import React from 'react';
import AppStyle from '../assets/colors/Appstyle';

const Footer = () => {
    return (
        <footer style={{ backgroundColor: AppStyle.dark, color: AppStyle.white }} className="py-4 text-center absolute  w-full mt-[10vh]">
            <p className="text-sm">
                &copy; {new Date().getFullYear()} Nabil El Hakimi. All Rights Reserved.
            </p>
        </footer>
    );
}

export default Footer;
