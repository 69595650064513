import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Slider from 'react-slick';

// Ensure you import the required CSS for react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Card = ({ id, images, title, description, githubLink, demoLink }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();

  const handleGitHubClick = (e) => {
    e.stopPropagation();
    window.open(githubLink, '_blank');
  };

  const handleDemoClick = (e) => {
    e.stopPropagation();
    window.open(demoLink, '_blank');
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="flex flex-col justify-center m-6 p-2 bg-[#303030] rounded-lg hover:bg-[#2d2d2d] shadow-black shadow-lg">
      <div className="relative mb-3">
        <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <div key={index} className="relative w-full h-52">
              <img
                src={image.url}
                alt={`Slide ${index}`}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ))}
        </Slider>
      </div>
      <Link
        to={`/projects/${id}`}
        onClick={scrollToTop}
        className='p-3 block'
      >
        <h2 className="text-[#E5F15E] text-2xl">{title}</h2>
        <div
          className={`text-gray-300 mt-2 ${!showFullDescription ? 'description-limited' : ''}`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {!showFullDescription && description.length > 100 && (
          <span 
            className="show-more"
            onClick={() => setShowFullDescription(true)}
          >
          </span>
        )}
      </Link>
      <div className='flex mt-4 justify-end'>
        {githubLink && (
          <button
            onClick={handleGitHubClick}
            className="bg-transparent hover:bg-[#000] text-[#FFF] font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded mr-2"
            aria-label="View GitHub repository"
          >
            GitHub
          </button>
        )}
        {demoLink && (
          <button
            onClick={handleDemoClick}
            className="bg-transparent hover:bg-blue-500 text-[#FFF] font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
            aria-label="View demo"
          >
            Demo
          </button>
        )}
      </div>
    </div>
  );
};

export default Card;
