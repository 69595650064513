import React, { useState } from 'react';
import MyImage from '../assets/images/7O2A0216.JPG';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaPhoneAlt, FaCopy, FaCheck, FaDiscord, FaPaperPlane } from 'react-icons/fa';
import { FaLinkedin, FaInstagram, FaWhatsapp, FaGithub } from 'react-icons/fa';

const Contact = () => {
  const [emailCopied, setEmailCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);

  const handleCopy = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      if (type === 'email') {
        setEmailCopied(true);
        setTimeout(() => setEmailCopied(false), 2000); 
      } else if (type === 'phone') {
        setPhoneCopied(true);
        setTimeout(() => setPhoneCopied(false), 2000); 
      }
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className='w-full flex items-center justify-center'>
      <div className="flex flex-col lg:flex-row items-center justify-center p-4 max-w-screen-lg mx-auto gap-0 w-full">
        {/* Image on the left */}
        <div className="w-full lg:w-auto flex-shrink-0 mb-0 lg:mb-0 shadow-lg">
          <Link to='/' className='flex w-full'>
            <img className='w-full lg:w-[22em] h-[45vh] lg:h-[28em] object-cover rounded-t-lg lg:rounded-lg' src={MyImage} alt="Profile" title='Nabil El Hakimi' />
          </Link>
        </div>
        
        {/* Content on the right */}
        <div className="flex flex-col items-center lg:items-start text-center lg:text-left p-4 bg-[#202020] rounded-b-lg lg:rounded-lg lg:rounded-l-none shadow-md w-full lg:max-w-md">
          <h1 className="text-2xl md:text-3xl lg:text-4xl text-[#E5F15E] font-bold mb-4">Contact Me</h1>
          <p className="text-white mb-4  pr-2 pl-2">
            Hi, I'm Nabil El Hakimi, a Full Stack Developer. With a passion for
            creating seamless and interactive web experiences, I specialize in building
            robust and user-friendly applications. I'm always excited to explore new
            technologies and solve complex problems. Feel free to reach out and connect with me!
          </p>
          
          <div className="flex gap-1 mb-6 w-full justify-center lg:justify-start">
            <a href="https://discordapp.com/users/1127998153593999602" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-1/5 p-4 bg-[#303030] rounded-lg shadow-md text-white hover:bg-black transition-colors duration-300">
              <FaDiscord size={36} />
            </a>
            <a href="https://www.linkedin.com/in/nabil-el-hakimi-91a69a285/" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-1/5 p-4 bg-[#303030] rounded-lg shadow-md text-white hover:bg-black transition-colors duration-300">
              <FaLinkedin size={36} />
            </a>
            <a href="https://www.instagram.com/e.lbigg/" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-1/5 p-4 bg-[#303030] rounded-lg shadow-md text-white hover:bg-black transition-colors duration-300">
              <FaInstagram size={36} />
            </a>
            <a href="https://wa.me/+212642109641" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-1/5 p-4 bg-[#303030] rounded-lg shadow-md text-white hover:bg-black transition-colors duration-300">
              <FaWhatsapp size={36} />
            </a>
            <a href="https://github.com/NabilElHakimi" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center w-1/5 p-4 bg-[#303030] rounded-lg shadow-md text-white hover:bg-black transition-colors duration-300">
              <FaGithub size={36} />
            </a>
          </div>
          
          <div className="flex items-center mb-4">
            <div className="flex items-center text-white text-sm md:text-base lg:text-lg mr-4">
              <FaEnvelope className="mr-2" />
              <p className="mr-2">Email: <span className='text-[#E5F15E]'>Hakimi.deve@gmail.com</span></p>
            </div>
            <div className="flex items-center text-white text-sm md:text-base lg:text-lg">
              <FaPaperPlane className="mr-2 cursor-pointer" onClick={() => window.location.href = "mailto:Hakimi.deve@gmail.com"} />
              <FaCopy className="cursor-pointer" onClick={(e) => { 
                e.stopPropagation();
                handleCopy('Hakimi.deve@gmail.com', 'email'); 
              }} />
              {emailCopied && <FaCheck className="text-green-500 ml-2" />}
            </div>
          </div>
          
          <div className="flex items-center">
            <div className="flex items-center text-white text-sm md:text-base lg:text-lg mr-4">
              <FaPhoneAlt className="mr-2" />
              <p className="mr-2">Phone: <span className='text-[#E5F15E]'>+212 642-109-641</span></p>
            </div>
            <div className="flex items-center text-white text-sm md:text-base lg:text-lg">
              <FaPhoneAlt className="mr-2 cursor-pointer" onClick={() => window.location.href = "tel:+212642109641"} />
              <FaCopy className="cursor-pointer" onClick={(e) => { 
                e.stopPropagation();
                handleCopy('+212642109641', 'phone'); 
              }} />
              {phoneCopied && <FaCheck className="text-green-500 ml-2" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
