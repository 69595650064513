import React from 'react';
import MyImage from '../assets/images/7O2A0216.JPG';
import AppStyle from '../assets/colors/Appstyle';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {

    return (
        <header style={{ backgroundColor: AppStyle.dark, color: 'white' }} className="flex flex-col md:flex-row justify-between items-center top-0 fixed w-full z-50">
           <Link to={"/"}> <div className="flex items-center cursor-pointer select-none hover:bg-dark p-4 md:p-6 md:pl-20 md:pr-20 hover:bg-[#2d2d2d] transition-all">
                <img className='w-10 h-10 md:w-[2.5em] md:h-[2.5em] rounded-full mr-2' src={MyImage} alt="Profile"  title='Nabil El Hakimi '/>
                <h1 className="text-xl md:text-2xl font-bold uppercase">Hakimi</h1>
            </div>
            </Link>
            <div className="w-full md:w-[18vw] flex justify-around md:justify-between uppercase p-4 md:pr-20" id='ListeOfPage'>
                <NavLink to="/" activeStyle={{ color: AppStyle.yellow }} className="text-sm md:text-base cursor-pointer select-none" title='Home'>Home</NavLink>
                <NavLink to="/projects" activeStyle={{ color: AppStyle.yellow }} className="text-sm md:text-base cursor-pointer select-none" title='Projects'>Projects</NavLink>
                <NavLink to="/contact" activeStyle={{ color: AppStyle.yellow }} className="text-sm md:text-base cursor-pointer select-none" title='Contact'>Contact</NavLink>
            </div>
        </header>
    );
}

function NavLink({ to, activeStyle, ...rest }) {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <Link to={to} style={isActive ? activeStyle : {}} {...rest} />
    );
}
