import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './Card';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://portfolio.elhakimi.com/api/projects')
      .then(response => {
        setProjects(response.data); 
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const skeletonCards = Array.from({ length: 6 }).map((_, index) => (
    <div key={index} className="flex flex-col justify-center m-2 p-2  bg-[#303030] rounded-lg hover:bg-[#2d2d2d]" id='skeletCard'>
      <div className="rounded-lg mb-3 skeleton" style={{ width: '100%', height: '200px' }}></div>
      <div className="skeleton" style={{ width: '80%', height: '28px' }}></div>
      <div className="flex mt-4 justify-end">
        <div className="skeleton" style={{ width: '100px', height: '40px' }}></div>
        <div className="skeleton ml-2" style={{ width: '100px', height: '40px' }}></div>
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4 md:mx-4 md:my-4 mx-0 my-0 md:mr-[8vw] md:ml-[8vw]">
        {loading ? skeletonCards : projects.map((project) => (
          <Card
            key={project.id}
            id={project.id}
            images={project.images}
            title={project.title}
            description={project.description}
            githubLink={project.github}
            demoLink={project.demo}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
