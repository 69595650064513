import React from 'react';
import MyImage from '../assets/images/nnb.jpg';
import AppStyle from '../assets/colors/Appstyle';
import { ReactTyped as Typed } from 'react-typed';

import {
    FaLaravel,
    FaReact,
    FaVuejs,
    FaPhp,
    FaDatabase,
    FaCss3Alt,
    FaBootstrap,
    FaJsSquare,
    FaHtml5,
} from 'react-icons/fa';

const Home = () => {
    const iconStyle = { color: AppStyle.white, marginRight: '10px' };
    const imageStyle = { width: '40px', height: '40px', objectFit: 'contain' };

    return (
        <div className='flex flex-col items-center'>
            <div className='flex flex-col md:flex-row justify-center  items-center mb-[10vh]'>
                <div>
                    <img
                        src={MyImage}
                        alt="Nabil El Hakimi"
                        className='rounded-xl  mb-3 h-[80vw] w-[80vw] md:h-[25vw] md:w-[25vw] shadow-black shadow-lg hover:shadow-xl
                         hover:shadow-black transition-all hover:borde ' 
                        style={{ transform: 'scaleX(-1)' }}
                        title='Nabil El Hakimi'
                    />
                </div>
                <div className='ml-0 md:ml-10 w-[80vw] md:w-[25vw] mt-5 md:mt-0 '>
                    <h1 className='text-3xl md:text-6xl font-bold text-white uppercase ' >Nabil</h1>
                    <h1 className='text-3xl md:text-6xl font-bold text-white uppercase'>El Hakimi</h1>
                    <Typed
                        strings={['Full Stack Developer','Web - Mobile', 'Laravel | ReactJs', 'Flutter | Dart']}
                        typeSpeed={80}
                        backSpeed={40}
                        loop
                        className={` text-xl md:text-3xl uppercase`}
                        style={{ color: AppStyle.yellow }}
                    />
                </div>
            </div>
            <div className='flex justify-center'>
                <div className='grid grid-cols-4 gap-4 md:grid-cols-6 md:gap-6 justify-items-center'>
                    <div className="icon-container">
                        <FaLaravel size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <FaReact size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <FaVuejs size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <FaPhp size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <FaDatabase size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <FaBootstrap size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <FaJsSquare size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <FaHtml5 size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <FaCss3Alt size={40} style={iconStyle} />
                    </div>
                    <div className="icon-container">
                        <img src="https://www.loopple.com/img/tailwind-logo.png" style={imageStyle} alt="Tailwind CSS" />
                    </div>
                    <div className="icon-container">
                        <img src="https://i.pinimg.com/originals/c3/02/36/c30236dd759f7f97d3586477046af7e8.png" style={imageStyle} alt="Flutter" />
                    </div>
                    <div className="icon-container">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Dart_programming_language_logo_icon.svg/2048px-Dart_programming_language_logo_icon.svg.png" style={imageStyle} alt="Dart" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
